import moment from 'moment-timezone';
import { dateFormatEnum } from '../enums/dateFormatEnum';

/**
 * @desc Expected values are Date, ISO string or moment date object
 * @param {String | Moment | Date} dateStartArgA
 * @param {String | Moment | Date} dateEndArgA
 * @param {String | Moment | Date} dateStartArgB
 * @param {String | Moment | Date} dateEndArgB
 * @return Boolean
 */
export const isOverlapping = ([dateStartArgA, dateEndArgA], [dateStartArgB, dateEndArgB]) => {
  const dateStartA = moment(dateStartArgA).toDate();
  const dateEndA = moment(dateEndArgA).toDate();
  const dateStartB = moment(dateStartArgB).toDate();
  const dateEndB = moment(dateEndArgB).toDate();

  // If same range
  if (dateStartA.getTime() === dateStartB.getTime() && dateEndA.getTime() === dateEndB.getTime()) {
    return true;
  }

  return (
    isWithinRange(dateStartA, [dateStartB, dateEndB]) ||
    isWithinRange(dateEndA, [dateStartB, dateEndB]) ||
    isWithinRange(dateStartB, [dateStartA, dateEndA]) ||
    isWithinRange(dateEndB, [dateStartA, dateEndA])
  );
};

/**
 * @desc Expected values are date objects
 * @param {Date} date
 * @param {Date} rangeStart
 * @param {Date} rangeEnd
 * @return Boolean
 */
export const isWithinRange = (date, [rangeStart, rangeEnd]) => date > rangeStart && date < rangeEnd;

/**
 * @desc returns formatted string from date and time in format: YYYY-MM-DDTHH:mm:ss.SSSSZ
 * @param {Date} date
 * @param {Date} time
 * @return string
 */
export const formatDateTime = (date, time) => moment(date + ' ' + time).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');

/**
 * @deprecated should use Date() instead
 * @desc returns now() date and time
 * @return {moment date}
 */
export const now = () => moment();

/**
 * @desc returns true if given date is today
 * @param {string | moment date} date
 * @return {boolean} - true if given date is today
 */
export const isToday = date => moment(date).isSame(now(), 'day');

/**
 * @desc returns given date plus amount of units
 * @param {string | moment date} date
 * @param {number} amount
 * @param {'days' | 'minutes' | 'years'} unit
 * @return {moment date }
 */

export const addToDate = (date, amount, unit) => moment(date).add(amount, unit);

/**
 * @desc returns true if given date is between fromDate and toDate
 * @param {string | moment date} date
 * @param {string | moment date} fromDate
 * @param {string | moment date} toDate
 * @param {"year" | "years" | "y" | "month" | "months" | "M" | "week" | "weeks" | "w" | "day" | "days" | "minute"} granularity
 * @param {"()" | "[)" | "(]) | "[]"} inclusivity
 * @return {boolean} - true if is between, false - otherwise
 */
export const isDateBetween = (date, fromDate, toDate, granularity, inclusivity = '()') =>
  moment(date).isBetween(fromDate, toDate, granularity, inclusivity);

/**
 * @desc returns true if given date is before compareDate
 * @param {string | moment date} date
 * @param {string | moment date} compareDate
 * @param {String | null} unit
 * @return {boolean} - true if is before, false - otherwise
 */
export const isBefore = (date, compareDate, unit = null) => moment(date).isBefore(moment(compareDate), unit);

/**
 * @desc returns true if given date is after compareDate
 * @param {string | moment date} date
 * @param {string | moment date} compareDate
 * @return {boolean} - true if is after, false - otherwise
 */
export const isAfter = (date, compareDate) => moment(date).isAfter(moment(compareDate));

/**
 * @desc returns true if given date is the same as compareDate
 * @param {string | moment date} date
 * @param {string | moment date} compareDate
 * @param {"year" | "years" | "y" | "month" | "months" | "M" | "week" | "weeks" | "w" | "day" | "days" | "minute"} unit
 * @return {boolean} - true if the same, false - otherwise
 */
export const isSame = (date, compareDate, unit = 'day') => moment(date).isSame(moment(compareDate), unit);

/**
 * @desc returns true if given date is the today ot after today
 * @param {string | moment date} date
 * @return {boolean} - true if today ot after today, false - if before today
 */
export const isTodayOrAfter = date => (date ? moment(date).isSameOrAfter(moment(), 'day') : true);

/**
 *
 * @desc return the given parameter to date and time
 * @param {string | moment date} date
 * @returns {moment date}
 */
export const getDate = date => moment(date);

/**
 * @desc Mutates the date by setting it to the start of a unit of time.
 * @param {string | moment | date} date
 * @param {string} unit
 * @return {moment}
 */
export const startOf = (date, unit) => moment(date).startOf(unit);

/**
 * @desc Mutates the date by setting it to the end of a unit of time.
 * @param {string | moment | date} date
 * @param {string} unit
 * @return {moment}
 */
export const endOf = (date, unit) => moment(date).endOf(unit);

/**
 * @desc Based on the provided date, get the start date of the week that the provided date is currently in
 * @param {string | Object} date
 * @param {string} format
 * @returns {string}
 */
export const startOfWeek = (date = null, format = dateFormatEnum.COMPLETE_DATE) =>
  moment(date || new Date())
    .startOf('isoWeek')
    .format(format);

/**
 * @desc Based on the provided date, get the end date of the week that the provided date is currently in
 * @param {string | Object} date
 * @param {string} format
 * @returns {string}
 *
 */
export const endOfWeek = (date = null, format = dateFormatEnum.COMPLETE_DATE) =>
  moment(date || new Date())
    .endOf('isoWeek')
    .format(format);

/**
 * @desc Mutates the date by setting it to the end of a unit of time.
 * @param {string | moment | date} date
 * @param {string?} dateFormat
 * @return {string}
 */
export const format = (date, dateFormat) => moment(date).format(dateFormat);

/**
 * @desc Formats the given date in UTC time.
 * @param {string | moment | Date} date
 * @param {string} [dateFormat]
 * @return {string}
 */
export const utcFormat = (date, dateFormat) => moment.utc(moment(date)).format(dateFormat);

/**
 * @desc return the iso weekday number
 * @param {string | moment | Date} date
 * @returns {number}
 */
export const getIsoWeekday = date => moment(date).isoWeekday();

/**
 * @desc returns given date plus amount of units
 * @param {string} date
 * @param {number} amount
 * @param {'days' | 'minutes' | 'years' | 'month'} unit
 * @return {string}
 */
export const add = (date, amount, unit) => moment(date).add(amount, unit).format();

/**
 * @desc compares 2 different formatted date strings: date1 using DD-MM-YYYY, date2 using YYYY-MM-DD
 * @param {string} date1
 * @param {string} date2
 * @return {boolean}
 */
export const isTheSameDate = (date1, date2) => {
  if (date1 && date2) {
    const timeTokens = date1.split('-'); // date1 using DD-MM-YYYY, date2 using YYYY-MM-DD
    const sameFormatDate = new Date([timeTokens[2], timeTokens[1], timeTokens[0]].join('-'));
    return sameFormatDate.getTime() === new Date(date2).getTime();
  }
  return false;
};

/**
 * @desc get the time difference in minute between 2 given moments, can return a negative value
 * @param timeA
 * @param timeB
 * @return {number}
 */
export const getTimeDifference = (timeA, timeB) => {
  const momentTimeA = moment(timeA, 'HH:mm');
  const momentTimeB = moment(timeB, 'HH:mm');
  const duration = moment.duration(momentTimeB.diff(momentTimeA));
  return duration.asMinutes();
};

/**
 * @desc get the days difference in days between 2 given moments, can return a negative value
 * @param dayA
 * @param dayB
 * @return {number}
 */
export const getDaysDifference = (dayA, dayB) => {
  const momentTimeA = moment(dayA, 'YYY-MM-DD');
  const momentTimeB = moment(dayB, 'YYY-MM-DD');
  const duration = moment.duration(momentTimeB.diff(momentTimeA));
  return duration.asDays();
};

/**
 * @desc check if the given date has valid format
 * @param date
 * @param format
 * @param strict
 * @return {boolean}
 */
export const isValid = (date, format = '', strict = false) => date != null && moment(date, format, strict).isValid();

export const getRelativeDateTime = date => {
  const comparedDate = moment(date).format(dateFormatEnum.COMPLETE_DATE);
  const today = moment().format(dateFormatEnum.COMPLETE_DATE);
  const dayDifference = moment(comparedDate).diff(today, 'days');
  const relativeTimeFormat = new Intl.RelativeTimeFormat('da', { numeric: 'auto' });

  switch (true) {
    case dayDifference > 0:
      return moment(date).format('DD. MMM. YYYY HH:mm');
    case dayDifference >= -1:
      return relativeTimeFormat.format(dayDifference, 'day') + moment(date).format(' HH:mm');
    case dayDifference > -7:
      return moment(date).format('ddd. HH:mm');
    case dayDifference > -30:
      return moment(date).format('ddd. DD. MMM. HH:mm');
    case dayDifference > -300:
      return moment(date).format('DD. MMM. HH:mm');
    default:
      return moment(date).format('DD. MMM. YYYY');
  }
};

/**
 * Get time in milisecond until the given hour
 * @param {number} hour
 * @param {number} minute
 * @return {number}
 */
export const getTimeUntil = (hour, minute = 0) => {
  const now = new Date();
  const givenTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute, 0, 0);
  if (now > givenTime) {
    givenTime.setDate(givenTime.getDate() + 1);
  }
  return givenTime - now;
};
